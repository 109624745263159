import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Channel, DefaultGenerics, MessageResponse, SearchMessageSort } from 'stream-chat';
import { ChannelService, ChatClientService, DefaultStreamChatGenerics, StreamI18nService, StreamMessage } from 'stream-chat-angular';
import { HttpService } from '../http.service';
import { ApiURL } from '../api';
import { AddMemberApi } from '../../shared/typings/chat.typing';
import { LocalstorageService } from '../localstorage.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { getApiParamSet } from '../../shared/function/function';
import { ChannelCreateValue, IAllFile, paginationFilter } from '../../shared/typings/app.typings';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
readCountChannelIds:string[] = [];
  constructor(
    private client: ChatClientService,
    private channel: ChannelService,
    private streamI18n: StreamI18nService,
    private httpService: HttpService,
    private localStorage: LocalstorageService
  ) { }

  channelIdsMap(channel:{channel_id:string}[]){
    this.readCountChannelIds = channel.map((x:{channel_id:string}) => { return x.channel_id})
  }


  async editMessage(message:{id:string,text:string}){
    //@ts-ignore
    return await this.channel.updateMessage(message);
  }

  async deleteMessage(message:any){
    //@ts-ignore
    return await this.channel.deleteMessage(message);
  }
  async searchMessageGallery(selected: IAllFile,channelCid:string) {
    const channelFilter = { cid: channelCid }
    let messageFilter = {};
    if(selected.type === 'mp3'){
      messageFilter = { "attachments.type": { $in: ['audio','voicenote'] }};
    }else{
      messageFilter = { "attachments.type": { $in: [`${selected.type}`]}};
    }
    return await this.client.chatClient.search(
      channelFilter,
      messageFilter,
      { sort: [ { created_at: -1 }],
      limit: selected.limit,offset:selected.page * 100}
    )
  }

  async searchMessageVideo(channelCid: string,) {
    const channelFilter = { cid: channelCid }
    // const sort = [{ last_message_at: -1 }]
      let messageFilter = { "attachments.type": { $in: ['video'] }}
    
    // 'image','video'
    return await this.client.chatClient.search(
      channelFilter,
      messageFilter
    )
  }

  async searchMessageFile(channelCid: string,) {
    const channelFilter = { cid: channelCid }
    // const sort = [{ last_message_at: -1 }]
      let messageFilter = { "attachments.type": { $in: ['file'] }}
    
    // 'image','video'
    return await this.client.chatClient.search(
      channelFilter,
      messageFilter
    )
  }

  async searchMessageAudio(channelCid: string,) {
    const channelFilter = { cid: channelCid }
    // const sort = [{ last_message_at: -1 }]
      let messageFilter = { "attachments.type": { $in: ['audio','voicenote'] }}
    
    // 'image','video'
    return await this.client.chatClient.search(
      channelFilter,
      messageFilter
    )
  }

  async searchMessage(channelCid: string, message: string) {
    const channelFilter = { cid: channelCid }
    const messageFilter = { text: {$autocomplete:  message }}
    const sort: SearchMessageSort = [
      {created_at: -1}         // Sort by ID ascending
    ];
    try{
      const searchResults = await this.client.chatClient.search(
        channelFilter,
        messageFilter,
        {
          sort: sort,  // Apply sorting
        }
      );
      return searchResults;
    }catch (error) {
      throw error;
    }
  }


  // get token 
  getToken() {
    return this.httpService.postAll(ApiURL.getToken)
  }

  // establish connection with chat
  async chatConnect() {
    if(environment.stream_api_key){
      const userDetails = this.localStorage.getItem('User Data')
      const streamToken = this.localStorage.getItem('streamToken')
      if (userDetails && streamToken) {
        const userId = (JSON.parse(userDetails)).employeeDetails.code
        this.streamI18n.setTranslation()
        await this.client.init(environment.stream_api_key, "EMP" + userId, streamToken).then(res => {})
      }
    }
  }


  // disconnect chat
  chatDisconnect() {
    this.client.disconnectUser()
  }

  getTemplateMessage(){
    return this.httpService.getAll(ApiURL.chatTemplateMessage)
  }
  getActiveUser(payload:{label:string,employeeIds:string[]}){
    // return this.httpService.getAll(`${ApiURL.activeUser}${key}`)
    return this.httpService.postAll(ApiURL.activeUser,payload)
  }

  getBroadcastHistory(payload:{senderId:string,next?:string}){
    return this.httpService.postAll(ApiURL.broadcastHistory,payload)
  }

  getChannelCount(){
    return this.httpService.getAll(ApiURL.unreadCount);
  }


  getChannelListing(payload:paginationFilter){
    return this.httpService.getAll(ApiURL.getChannelListing,getApiParamSet(payload))
  }
  createChannelMaster(payload:ChannelCreateValue){
    return this.httpService.postAll(ApiURL.createChannelMaster,payload)
  }


  broadcastDelete(payload:{campaignId:string}){
    return this.httpService.postAll(ApiURL.broadcastDelete,payload)
  }
  broadcastUpdate(payload:{campaignId: string; text: string }){
    return this.httpService.postAll(ApiURL.broadcastUpdate,payload)
  }

  // create channel 
  createChannel(payload: AddMemberApi): Observable<any> {
    return this.httpService.postAll(ApiURL.addMember, payload)
  }

  // get all chats with the logged in user
  async getChats(page:number | undefined = undefined) {
    // const userDetails = this.localStorage.getItem('User Data')
    // const userId = userDetails ? ((JSON.parse(userDetails)).employeeDetails.code) : ""
    // const filter = { type: "messaging", members: { $in: ["EMP" + userId] } }
    // const sort = [{ last_message_at: -1 }]
    // const pagination = {limit:10,offset:page ?page:0}
    
    // const channels = await this.client.chatClient.queryChannels(filter, sort, pagination)
    // return channels
  }

  async getChatsFilter(keyWord:string | null = null,page:number = 0,selectedCid:string[],unreadMessage:boolean = false, ids:string[] | undefined,subActive:boolean = false,subActiveIds:string[] = []){
    let filter:any;
    if(ids && ids.length){
        filter = { type: "messaging", members: { $in: ids}}
    }else{
        const userDetails = this.localStorage.getItem('User Data')
        const userId = userDetails ? ((JSON.parse(userDetails)).employeeDetails.code) : "";
        filter = { type: "messaging", members: { $in: ["EMP" + userId]}}
       }

    // filter = { type: "messaging", id: { '$in': ['505051619-group'] }, members: { $in: ["EMP" + userId]}}
    //  let filter:any = { type: "messaging", members: { $in: ["EMP" + 'EC103',"EMP" + userId]}}

    if(subActive){
      filter['id'] = { $in: subActiveIds }
    }
    
    if(selectedCid.length){
        filter["cid"] = { $in: selectedCid};
    }
    if(keyWord !=null){
      filter["member.user.name"] = { $autocomplete: keyWord}
    }
    let sort:any = [];
    if(unreadMessage){
      sort = [{has_unread: -1, last_message_at: -1 }]
    }else{
      sort = [{last_message_at: -1 }]
    }
    const pagination = {limit:10,offset:page ?page*10:0}
    // @ts-ignore
    const channels = await this.client.chatClient.queryChannels(filter, sort, pagination)
    return channels
  }

  // send text message
  async sendMessage(channel: Channel<DefaultStreamChatGenerics>, data: any, attachments?: any[]) {
    const userDetails = this.localStorage.getItem('User Data')
    const userId = userDetails ? ((JSON.parse(userDetails)).employeeDetails.code) : ""
    let message: any = {
      cid: '',
      created_at: new Date(),
      deleted_reply_count: 0,
      html: '',
      attachments: [],
      id: '',
      latest_reactions: [],
      mentioned_users: [],
      own_reactions: [],
      pin_expires: '',
      pinned: false,
      pinned_at: {},
      pinned_by: {},
      reaction_counts: {},
      reply_count: 0,
      shadowed: false,
      silent: false,
      text: data,
      type: {},
      updated_at: new Date(),
      user: { id: "EMP" + userId }
    };
    if (attachments) {
      message.attachments = attachments
    }
    await channel.sendMessage({
      text: data,
      attachments: attachments ? attachments : []
    }).then((res) => {
      message.cid = String(res.message.cid);
      message.created_at = new Date();
      message.attachments = res.message.attachments;
      message.deleted_reply_count = Number(res.message.deleted_reply_count);
      message.html = String(res.message.html);
      message.id = res.message.id;
      message.latest_reactions = res.message.latest_reactions;
      message.mentioned_users = res.message.mentioned_users;
      message.own_reactions = res.message.own_reactions;
      message.pin_expires = res.message.pin_expires;
      message.pinned = res.message.pinned;
      message.pinned_at = moment(res.message.pinned_at).toDate();
      message.pinned_by = res.message.pinned_by;
      message.reaction_counts = res.message.reaction_counts;
      message.reply_count = res.message.reply_count;
      message.shadowed = res.message.shadowed;
      message.silent = res.message.silent;
      message.text = res.message.text;
      message.type = res.message.type;
      message.updated_at = moment(res.message.updated_at).toDate();
      message.user = res.message.user;
    })
    return message;
  }

  // get channel using channel ID
  async getChannel(cid: string) {
    const filter = { type: "messaging", cid: { $eq: cid } }
    // @ts-ignore
    const channels = await this.client.chatClient.queryChannels(filter)
    if (channels[0])
      return channels[0]
    else
      return null
  }

  // get channel by channel ID
  async getChannelById(id: string) {
    // return await this.client.chatClient.queryChannels({ cid: { $eq: id } })
    return await this.client.chatClient.queryChannels({ id: { $eq: id } })
  }

  // send File 
  async sendFile(channel: Channel<DefaultStreamChatGenerics>, file: File, text?: string) {
    let data = await channel.sendFile(file);
    return data
  }

  async sendImage(channel: Channel<DefaultStreamChatGenerics>, file: File, text?: string) {
    let data = await channel.sendImage(file);
    return data
  }


  // set active channel - route to channel from profile
  setActiveChannel(channel: any) {
    this.channel.setAsActiveChannel(channel)
  }

   // get channel by channel ID
  async getChannelByUserId(id: string) {
    return await this.client.chatClient.queryChannels({id: {$eq: id}})
  }

  createLabel(payload: {label: string}) {
    return this.httpService.postAll(ApiURL.createLabels, payload)
  }

  getLabels() {
    return this.httpService.getAll(ApiURL.getLabels)
  }

  assignLabel(payload: {labelId: string, groupIds: string[]}) {
    return this.httpService.postAll(ApiURL.assignLabels, payload)
  }

  getLabelChannels(label: {label: string}) {
    return this.httpService.getAll(ApiURL.getLabelChannels,getApiParamSet(label))
  }
  
  sendBroadcast(payload: any) {
    return this.httpService.postAll(ApiURL.broadcast, payload)
  }

  editLabel(payload: {newLabel: string,id:string}) {
    return this.httpService.putApi(ApiURL.editLabel,payload.id,{newLabel:payload.newLabel})
  }

  deleteLabel(label: string) {
    return this.httpService.deleteOne(ApiURL.deleteLabel,label)
  }

  getBroadcastList() {
    return this.httpService.getAll(ApiURL.getBroadcastList)
  }

  updateBroadcast(id: string, payload: {userId: string, userName: string}[]) {
    const url = `${ApiURL.updateBroadcast}/${id}`
    return this.httpService.putOnlyUrlApi(url, {updateMembers: payload})
  }

  deleteBroadcast(id: string) {
    return this.httpService.deleteOne(`${ApiURL.deleteBroadcast}`, id)
  }

  makeBroadcast(id: string, message: any) {
    return this.httpService.postAll(`${ApiURL.makeBroadcast}/${id}`, message)
  }
  
  async reply(channel: Channel<DefaultStreamChatGenerics>, messageId: string, text: string, attachments?: any[]) {
    const userDetails = this.localStorage.getItem('User Data')
    const userId = userDetails ? ((JSON.parse(userDetails)).employeeDetails.code) : ""
    let message: any = {
      cid: '',
      created_at: new Date(),
      deleted_reply_count: 0,
      html: '',
      attachments: [],
      id: '',
      latest_reactions: [],
      mentioned_users: [],
      own_reactions: [],
      pin_expires: '',
      pinned: false,
      pinned_at: {},
      pinned_by: {},
      reaction_counts: {},
      reply_count: 0,
      shadowed: false,
      silent: false,
      text: text,
      type: {},
      updated_at: new Date(),
      user: { id: "EMP" + userId }
    };
    if (attachments) {
      message.attachments = attachments
    }
    await channel.sendMessage({
      text: text,
      attachments: attachments ? attachments : [],
      quoted_message_id: messageId
    }).then((res) => {
      message.cid = String(res.message.cid);
      message.created_at = new Date();
      message.attachments = res.message.attachments;
      message.deleted_reply_count = Number(res.message.deleted_reply_count);
      message.html = String(res.message.html);
      message.id = res.message.id;
      message.latest_reactions = res.message.latest_reactions;
      message.mentioned_users = res.message.mentioned_users;
      message.own_reactions = res.message.own_reactions;
      message.pin_expires = res.message.pin_expires;
      message.pinned = res.message.pinned;
      message.pinned_at = moment(res.message.pinned_at).toDate();
      message.pinned_by = res.message.pinned_by;
      message.reaction_counts = res.message.reaction_counts;
      message.reply_count = res.message.reply_count;
      message.shadowed = res.message.shadowed;
      message.silent = res.message.silent;
      message.text = res.message.text;
      message.type = res.message.type;
      message.updated_at = moment(res.message.updated_at).toDate();
      message.user = res.message.user;
    })
    return message;
  }
}
