import { sildeMenu } from "../../shared/typings/app.typings";

export const sidebarMenu: sildeMenu[] = [
    {
        id: 1,
        icon: '../../../../assets/images/icons/icon-calender.svg',
        title: 'Dashboard',
        routerLink: 'dashboard',
        permission: false,
        name: 'dashboard',
        subMenu: [],
        siblings: [
            {
                icon: '../../../../assets/images/sidebar-icons/dashboard.svg',
                title: 'My Dashboard',
                routerLink: 'my-dashboard',
                siblings: [],
                name: 'my-dashboard',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/tasks.svg',
                title: 'Today’s Tasks',
                routerLink: 'today-task',
                siblings: [],
                name: 'today-task',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/weight-stuck.svg',
                title: 'Weight Stuck',
                routerLink: 'weight-stuck',
                siblings: [],
                name: 'weight-stuck',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/opportunities.svg',
                title: 'Opportunities Dashboard',
                routerLink: 'opportunities-dashboard',
                siblings: [],
                name: 'opportunities-dashboard',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/CSAT.svg',
                title: 'CSAT',
                routerLink: 'CSAT-dashboard',
                siblings: [],
                name: 'CSAT',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/sales.svg',
                title: 'Sales Dashboard',
                routerLink: 'sales-dashboard',
                siblings: [],
                name: 'sales-dashboard',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/referral.svg',
                title: 'Referral Module',
                routerLink: 'referral',
                siblings: [],
                name: 'referral',
                action: {},
                listingAction: [],
                permission: true,
            },
            // {
            //     icon: '../../../../assets/images/sidebar-icons/call.svg',
            //     title: 'Calls Dashboard',
            //     routerLink: 'calls-dashboard',
            //     siblings: [],
            //     name: 'calls-dashboard',
            //     action: {},
            //     listingAction: [],
            //     permission: false,
            // },
            // {
            //     icon: '../../../../assets/images/sidebar-icons/reviews.svg',
            //     title: 'Review & Transformation',
            //     routerLink: 'review-transformation',
            //     siblings: [],
            //     name: 'review-transformation',
            //     action: {},
            //     listingAction: [],
            //     permission: false,
            // },
            // {
            //     icon: '../../../../assets/images/icons/icon-calender.svg',
            //     title: 'NPS',
            //     routerLink: 'NPS',
            //     siblings: [],
            //     name: 'NPS',
            //     action: {},
            //     listingAction: [],
            //     permission: false,
            // },
            // {
            //     icon: '../../../../assets/images/sidebar-icons/approvals.svg',
            //     title: 'Approvals',
            //     routerLink: 'approvals',
            //     siblings: [],
            //     name: 'approvals',
            //     action: {},
            //     listingAction: [],
            //     permission: false,
            // },
            // {
            //     icon: '../../../../assets/images/sidebar-icons/payment.svg',
            //     title: 'Payments & Sales',
            //     routerLink: 'payments-sales',
            //     siblings: [],
            //     name: 'payments-sales',
            //     action: {},
            //     listingAction: [],
            //     permission: false,
            // },
            // {
            //     icon: '../../../../assets/images/sidebar-icons/team-tasks.svg',
            //     title: 'Team Tasks',
            //     routerLink: 'team-tasks',
            //     siblings: [],
            //     name: 'team-tasks',
            //     action: {},
            //     listingAction: [],
            //     permission: false,
            // },
            // {
            //     icon: '../../../../assets/images/icons/icon-calender.svg',
            //     title: 'Dietitian Dashboard',
            //     routerLink: 'dietitian-dashboard',
            //     siblings: [],
            //     name: 'dietitian-dashboard',
            //     action: {},
            //     listingAction: [],
            //     permission: false,
            // }
        ]
    },
    {
        id: 2,
        icon: '../../../../assets/images/icons/icon-calender.svg',
        title: 'Sales & Revenue',
        routerLink: 'sales-revenue',
        permission: false,
        name: 'sales-revenue',
        subMenu: [
            {
                icon: '../../../../assets/images/sidebar-icons/order-payments.svg',
                title: 'Orders',
                routerLink: 'orders',
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/payment.svg',
                title: 'Payments',
                routerLink: 'payments',
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/calculation.svg',
                title: 'Calculator',
                routerLink: 'calculator',
                permission: true
            },
            {
                icon: '../../../../assets/images/sidebar-icons/approvals.svg',
                title: 'Approvals',
                routerLink: 'approvals',
                permission: true,
            }
        ],
        siblings: [
            {
                icon: '../../../../assets/images/sidebar-icons/order-payments.svg',
                title: 'Orders',
                routerLink: 'orders',
                siblings: [],
                name: 'orders',
                action: {
                    add: false,
                    edit: false,
                    delete: false,
                    view: false
                },
                listingAction: ['create-order','update-order'],
                crudAction: [
                    { name: 'create-order', type: 'plan'},
                    { name: 'update-order', type: 'Edit'},
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/payment.svg',
                title: 'Payments',
                routerLink: 'payments',
                siblings: [],
                name: 'payments',
                action: {
                    add: false,
                    edit: false,
                    delete: false,
                    view: false
                },
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/store.svg',
                title: 'Customer View',
                routerLink: 'customer-view',
                siblings: [],
                name: 'customer-view',
                action: {},
                listingAction: [],
                permission: true,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/calculation.svg',
                title: 'Calculator',
                routerLink: 'calculator',
                siblings: [],
                name: 'calculator',
                action: {},
                listingAction: [],
                permission: true,
            }, 
            {
                icon: '../../../../assets/images/sidebar-icons/approvals.svg',
                title: 'Approvals',
                routerLink: 'approvals',
                siblings: [],
                name: 'approvals',
                action: {},
                listingAction: [],
                permission: true,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/reviews.svg',
                title: 'employee-Otp',
                routerLink: 'employee-otp',
                siblings: [],
                name: 'employee-otp',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/warehouse-solid.svg',
                title: 'Logistics',
                routerLink: 'logistics',
                siblings: [],
                name: 'logistics',
                action: {},
                listingAction: [],
                permission: true,
            },
            // {
            //     icon: '../../../../assets/images/sidebar-icons/store.svg',
            //     title: 'Store',
            //     routerLink: 'store',
            //     siblings: [],
            //     name: 'dietitian-dashboard',
            //     action: {},
            
            //     listingAction: [],
            //     permission: false,
            // }
        ]
    },
    {
        id: 3,
        icon: '../../../../assets/images/icons/icon-calender.svg',
        title: 'Manage Users',
        routerLink: 'manage-users',
        name: 'manage-users',
        permission: false,
        subMenu: [
            {
                icon: '../../../../assets/images/icons/icon-calender.svg',
                title: 'Manage Members',
                routerLink: 'manage-members',
                permission: false,
            },
            {
                icon: '../../../../assets/images/icons/icon-calender.svg',
                title: 'Roster',
                routerLink: 'roster',
                permission: false,
            },
            {
                icon: '../../../../assets/images/icons/icon-calender.svg',
                title: 'Chats',
                routerLink: 'chats',
                permission: false,
            },
            {
                icon: '../../../../assets/images/icons/icon-calender.svg',
                title: 'Calls',
                routerLink: 'calls',
                permission: false,
            },
        ],
        siblings: [
            {
                icon: '../../../../assets/images/sidebar-icons/members.svg',
                title: 'Manage Members',
                routerLink: 'manage-members',
                siblings: [
                    {
                        icon: '../../../../assets/images/sidebar-icons/user-profile.svg',
                        title: 'Profile',
                        routerLink: 'profile',
                        name: 'profile',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/progress.svg',
                        title: 'Progress',
                        routerLink: 'progress',
                        name: 'progress',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/timelines.svg',
                        title: 'Timelines',
                        routerLink: 'timelines',
                        name: 'timelines',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/subscription.svg',
                        title: 'Subscription & Payments',
                        routerLink: 'subscription-payment',
                        name: 'subscription-payment',
                        action: {
                            add: false,
                            edit: false,
                            delete: false,
                            view: false
                        },
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/suggest-diet.svg',
                        title: 'Suggest Diet',
                        routerLink: 'suggest-diet',
                        name: 'suggest-diet',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/suggest-exercise.svg',
                        title: 'Suggest Exercise',
                        routerLink: 'suggest-exercise',
                        name: 'suggest-exercise',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/yoga.svg',
                        title: 'Suggest Yoga',
                        routerLink: 'suggest-yoga',
                        name: 'suggest-yoga',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/mind-activity.svg',
                        title: 'Suggest Mind Activity',
                        routerLink: 'suggest-mind-activity',
                        name: 'suggest-mind-activity',
                        action: {},
                        listingAction: ['mind-counselling'],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/counselling.svg',
                        title: 'Counselling',
                        routerLink: 'counselling',
                        name: 'counselling',
                        action: {},
                        listingAction: [
                            'start-counselling', 'book-slots'
                        ],
                        crudAction: [
                            { name: 'start-counselling', type: 'StartCounselling'},
                            { name: 'book-slots', type: 'BookSlots' },
                        ],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/followups.svg',
                        title: 'Follow Ups',
                        routerLink: 'follow-ups',
                        name: 'follow-ups',
                        action: {},
                        listingAction: ['start-followup'],
                        crudAction: [
                            { name: 'start-followup', type: 'StartFollowUp' },
                        ],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/CSAT.svg',
                        title: 'CSAT',
                        routerLink: 'CSAT',
                        name: 'CSAT',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/assessments.svg',
                        title: 'Assessments',
                        routerLink: 'assessments',
                        name: 'assessments',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/medical-report.svg',
                        title: 'Medical Reports',
                        routerLink: 'medical-reports',
                        name: 'medical-reports',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/guidelines.svg',
                        title: 'Guidelines',
                        routerLink: 'guidelines',
                        name: 'guidelines',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/chats-calls.svg',
                        title: 'Chats & Calls',
                        routerLink: 'chats',
                        name: 'chats-calls',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/call.svg',
                        title: 'Customer Calls Logs',
                        routerLink: 'customer-calls-logs',
                        name: 'customer-calls-logs',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/transformation.svg',
                        title: 'Transformation',
                        routerLink: 'transformations',
                        name: 'user_transformations',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/notes.svg',
                        title: 'RM Notes',
                        routerLink: 'rm-notes',
                        name: 'rm-notes',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                    {
                        icon: '../../../../assets/images/sidebar-icons/doctor.svg',
                        title: 'Doctor Notes',
                        routerLink: 'doctor-notes',
                        name: 'doctor-notes',
                        action: {},
                        listingAction: [],
                        permission: false
                    },
                ],
                name: 'manage-members',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/counselling.svg',
                title: 'Roster',
                routerLink: 'roster',
                siblings: [],
                name: 'roster',
                action: {},
                listingAction: [
                    'shuffle-counselling',
                    'reschedule-counselling',
                    'specific-dietitian',
                    'skill-set'
                ],
                crudAction: [
                    { name: 'shuffleCounselling', type: 'ShuffleCounselling' },
                    {name: 'rescheduleCounselling' , type : 'RescheduleCounselling'},
                    {name: 'specificDietitian' , type : 'SpecificDietitian'},
                    {name: 'skillSet' , type : 'skillSet'}

                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/icons/bulk-shuffle.svg',
                title: 'Bulk Client Shuffling',
                routerLink: 'bulk-client-shifting',
                siblings: [],
                name: 'bulk-client-shifting',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/referral.svg',
                title: 'Referral',
                routerLink: 'referral',
                siblings: [],
                name: 'referral',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/chat.svg',
                title: 'Chats',
                routerLink: 'chats',
                siblings: [],
                name: 'chats',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/call.svg',
                title: 'Calls Logs',
                routerLink: 'calls-logs',
                siblings: [],
                name: 'calls-logs',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/wellness.svg',
                title: 'Wellness',
                routerLink: 'wellness',
                siblings: [],
                name: 'wellness',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/wellness-coaches.svg',
                title: 'Wellness Coaches',
                routerLink: 'wellness-coaches',
                siblings: [],
                name: 'wellness-coaches',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/book-appointment.svg',
                title: 'Lab Tests',
                routerLink: 'lab-test',
                siblings: [],
                name: 'lab-test',
                action: {},
                listingAction: ['book-appointment'],
                crudAction: [
                    { name: 'book-appointment', type: 'Create Booking' },
                ],
                permission: false
            },
        ]
    },
    {
        id: 4,
        icon: '../../../../assets/images/icons/icon-calender.svg',
        title: 'Me',
        routerLink: 'me',
        name: 'me',
        permission: false,
        subMenu: [],
        siblings: [
            {
                icon: '../../../../assets/images/sidebar-icons/profile.svg',
                title: 'My Profile',
                routerLink: 'my-profile',
                siblings: [],
                name: 'my-profile',
                action: {},
                listingAction: ['view-profile'],
                crudAction: [
                    { name: 'view-profile', type: 'View' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/team.svg',
                title: 'Roster Management',
                routerLink: 'roster-management',
                siblings: [],
                name: 'roster-management',
                action: {},
                listingAction: ['view-employee'],
                crudAction: [
                    { name: 'view-employee', type: 'View' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/availability.svg',
                title: 'Roster Slots',
                routerLink: 'roster-slots',
                siblings: [],
                name: 'roster-slots',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/unavailability.svg',
                title: 'Unavailability',
                routerLink: 'unavailability',
                siblings: [],
                name: 'unavailability',
                action: {},
                listingAction: [],
                permission: false,
            },
        ]
    },
    {
        id: 5,
        icon: '../../../../assets/images/icons/icon-calender.svg',
        title: 'Organization',
        routerLink: 'organization',
        name: 'organization',
        permission: false,
        subMenu: [],
        siblings: [
            {
                icon: '../../../../assets/images/sidebar-icons/employees.svg',
                title: 'Manage Employees',
                routerLink: 'manage-employees',
                siblings: [],
                name: 'manage-employees',
                action: {},
                listingAction: ['add-employee', 'update-employee', 'view-employee'],
                crudAction: [
                    { name: 'add-employee', type: 'Add' },
                    { name: 'update-employee', type: 'Update' },
                    { name: 'view-employee', type: 'View' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/user-role.svg',
                title: 'System Users',
                routerLink: 'system-users',
                siblings: [],
                name: 'system-users',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/roles.svg',
                title: 'Manage Roles',
                routerLink: 'manage-roles',
                siblings: [],
                name: 'manage-roles',
                action: {},
                listingAction: ['add-role', 'view-role', 'update-role'],
                crudAction: [
                    { name: 'add-role', type: 'AddRole' },
                    { name: 'view-role', type: 'View' },
                    { name: 'update-role', type: 'Update' }
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/configuration.svg',
                title: 'Employee Configuration',
                routerLink: 'employee-configuration',
                siblings: [],
                name: 'employee-configuration',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/configuration.svg',
                title: 'Roaster Configuration',
                routerLink: 'roaster-configuration',
                siblings: [],
                name: 'roaster-configuration',
                action: {},
                listingAction: [],
                permission: false,
            },
        ]
    },
    {
        id: 6,
        icon: '../../../../assets/images/icons/icon-calender.svg',
        title: 'Master Data',
        routerLink: 'master-data',
        permission: false,
        name: 'master-data',
        subMenu: [],
        siblings: [
            {
                icon: '../../../../assets/images/sidebar-icons/weekly-diet.svg',
                title: 'Weekly Diet Sets',
                routerLink: 'weekly-diet-sets',
                siblings: [],
                name: 'weekly-diet-sets',
                action: {},
                listingAction: ['add-weekly-diet', 'view-weekly-diet', 'edit-weekly-diet'],
                crudAction: [
                    { name: 'add-weekly-diet', type: 'Add' },
                    { name: 'edit-weekly-diet', type: 'Edit' },
                    { name: 'view-weekly-diet', type: 'View' }

                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/suggest-diet.svg',
                title: 'Daily Diet Sets',
                routerLink: 'daily-diet-sets',
                siblings: [],
                name: 'daily-diet-sets',
                action: {},
                listingAction: ['add-daily-diet', 'edit-daily-diet', 'view-daily-diet'],
                crudAction: [
                    { name: 'add-daily-diet', type: 'Add' },
                    { name: 'edit-daily-diet', type: 'Edit' },
                    { name: 'view-daily-diet', type: 'View' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/recipes.svg',
                title: 'Recipes Sets',
                routerLink: 'recipes-sets',
                siblings: [],
                name: 'recipes-sets',
                action: {},
                listingAction: ['add-recipe', 'edit-recipe'],
                crudAction: [
                    { name: 'add-recipe', type: 'Add' },
                    { name: 'edit-recipe', type: 'Edit' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/suggest-exercise.svg',
                title: 'Weekly Exercise Sets',
                routerLink: 'weekly-exercise-sets',
                siblings: [],
                name: 'weekly-exercise-sets',
                action: {},
                listingAction: ['add-weekly-exercise', 'edit-weekly-exercise', 'view-weekly-exercise'],
                crudAction: [
                    { name: 'add-weekly-exercise', type: 'Add' },
                    { name: 'edit-weekly-exercise', type: 'Edit' },
                    { name: 'view-weekly-exercise', type: 'View' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/exercise.svg',
                title: 'exercises Sets',
                routerLink: 'exercises-sets',
                siblings: [],
                name: 'exercises-sets',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/weekly-yoga.svg',
                title: 'Weekly Yoga Sets',
                routerLink: 'weekly-yoga-sets',
                siblings: [],
                name: 'weekly-yoga-sets',
                action: {},
                listingAction: ['add-weekly-yoga', 'edit-weekly-yoga', 'view-weekly-yoga'],
                crudAction: [
                    { name: 'add-weekly-yoga', type: 'Add' },
                    { name: 'edit-weekly-yoga', type: 'Edit' },
                    { name: 'view-weekly-yoga', type: 'View' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/yoga.svg',
                title: 'Yoga Sets',
                routerLink: 'yoga-sets',
                siblings: [],
                name: 'yoga-sets',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/weekly-mind-activity.svg',
                title: 'Weekly Mind Activity Sets',
                routerLink: 'weekly-mind-activity',
                siblings: [],
                name: 'weekly-mind-activity',
                action: {},
                listingAction: ['add-weekly-activity', 'edit-weekly-activity', 'view-weekly-activity'],
                crudAction: [
                    { name: 'add-weekly-activity', type: 'Add' },
                    { name: 'edit-weekly-activity', type: 'Edit' },
                    { name: 'view-weekly-activity', type: 'View' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/mind-activity.svg',
                title: 'Mind Activity',
                routerLink: 'mind-activity',
                siblings: [],
                name: 'mind-activity',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/category.svg',
                title: 'Product Category',
                routerLink: 'product-category',
                siblings: [],
                name: 'product-category',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/promises.svg',
                title: 'Promise',
                routerLink: 'promise',
                siblings: [],
                name: 'promise',
                action: {},
                listingAction: [],
                permission: true,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/products.svg',
                title: 'Manage Products ',
                routerLink: 'manage-products',
                siblings: [],
                name: 'manage-products',
                action: {
                    add: false,
                    edit: false,
                    delete: false,
                    view: false
                },
                listingAction: ['add-product', 'edit-product'],
                crudAction: [
                    { name: 'add-product', type: 'Add' },
                    { name: 'edit-product', type: 'Edit' },

                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/bundles.svg',
                title: 'Manage Products Bundle',
                routerLink: 'product-bundle',
                siblings: [],
                name: 'product-bundle',
                action: {
                    add: false,
                    edit: false,
                    delete: false,
                    view: false
                },
                listingAction: ['add-bundle', 'edit-bundle'],
                crudAction: [
                    { name: 'add-bundle', type: 'Add' },
                    { name: 'edit-bundle', type: 'Edit' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/discount.svg',
                title: 'Manage Discount',
                routerLink: 'discount',
                siblings: [],
                name: 'discount',
                action: {
                    add: false
                },
                listingAction: ['add-discount', 'edit-discount', 'view-discount'],
                crudAction: [
                    { name: 'add-discount', type: 'Add' },
                    { name: 'edit-discount', type: 'Edit' },
                    { name: 'view-discount', type: 'View' },
                ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/challenge.svg',
                title: 'Challenge',
                routerLink: 'challenge',
                siblings: [],
                name: 'challenge',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/channel.svg',
                title: 'Chat Channel',
                routerLink: 'chat-channel',
                siblings: [],
                name: 'chat-channel',
                action: {},
                listingAction: ['create-channel', 'update-channel'],
                crudAction: [
                    { name: 'create-channel', type: 'create' },
                    { name: 'update-channel', type: 'update' },
                ],
                permission: true,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/medical-report.svg',
                title: 'Medical Condition',
                routerLink: 'medical-condition',
                siblings: [],
                name: 'medical-condition',
                action: {},
                listingAction: [],
                // listingAction: ['add-recipe', 'edit-recipe'],
                // crudAction: [
                //     { name: 'add-recipe', type: 'Add'},
                //     { name: 'edit-recipe', type: 'Edit' },
                // ],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/guidelines.svg',
                title: 'Guidelines Sets',
                routerLink: 'guidelines-sets',
                siblings: [],
                name: 'guidelines-sets',
                action: {},
                listingAction: [],
                // listingAction: ['add-recipe', 'edit-recipe'],
                // crudAction: [
                //     { name: 'add-recipe', type: 'Add'},
                //     { name: 'edit-recipe', type: 'Edit' },
                // ],
                permission: false,
            }
        ]
    },
    {
        id: 7,
        icon: '../../../../assets/images/icons/icon-calender.svg',
        title: 'Contents',
        routerLink: 'contents',
        name: 'contents',
        permission: false,
        subMenu: [],
        siblings: [
            {
                icon: '../../../../assets/images/sidebar-icons/banner.svg',
                title: 'Banners',
                routerLink: 'banners',
                siblings: [],
                name: 'banners',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/stories.svg',
                title: 'Stories',
                routerLink: 'stories',
                siblings: [],
                name: 'stories',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/posts.svg',
                title: 'Posts',
                routerLink: 'posts',
                siblings: [],
                name: 'posts',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '../../../../assets/images/sidebar-icons/transformation.svg',
                title: 'Transformations',
                routerLink: 'transformations',
                siblings: [],
                name: 'transformations',
                action: {},
                listingAction: [],
                permission: false,
            },
        ]
    },
    {
        id: 8,
        icon: '',
        title: 'Navbar',
        routerLink: '',
        name: 'Navbar',
        permission: false,
        subMenu:[],
        siblings:[
            {
                icon: '',
                title: 'Report Issue',
                routerLink: '',
                siblings: [],
                name: 'Report Issue',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '',
                title: 'Search Member',
                routerLink: '',
                siblings: [],
                name: 'Search Member',
                action: {},
                listingAction: [],
                permission: false,
            },
            {
                icon: '',
                title: 'Profile Section',
                routerLink: '',
                siblings: [],
                name: 'Profile Section',
                action: {},
                listingAction: [],
                permission: false,
            }
        ]
    },
]
